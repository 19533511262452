<template>
  <div class="body fixed mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <Spinner :spin="spin" />
    <!-- Header -->
    <section class="header activity_detail">
      <a
        class="icon iconButton third"
        @click.prevent="$router.push('/service/on-site-activities')"
      >
        <font-awesome-icon
          icon="fa-solid fa-chevron-left"
          size="lg"
        ></font-awesome-icon>
      </a>
      <span class="fL">實體活動報到</span>
      <div class="icon iconButton"></div>
    </section>
    <!-- Main -->
    <section class="main activity_detail">
      <!-- <div class="img cover">
        <img src="@/assets/img/ticket-cover.png" />
      </div> -->
      <div class="flexV">
        <span class="fXXL third">{{ campaign.campaignName }}</span>
        <span class="fM gray">{{ campaign.departmentName }}</span>
        <span class="fM gray"
          >{{ tsToDatetime(campaign.campaignStartTime) }} -
          {{ tsToDatetime(campaign.campaignEndTime) }}</span
        >
      </div>
      <span class="fM">{{ campaign.campaignDesc }}</span>
      <a
        class="button rounded"
        v-if="!campaign.hasCompleted"
        @click.prevent="barcodePopupOpen = true"
      >
        <span class="fM">開啟我的條碼</span>
      </a>
    </section>
    <BarcodePopup
      v-if="barcodePopupOpen"
      @closePopup="barcodePopupOpen = false"
    />
  </div>
</template>
<script>
import moment from 'moment-timezone';
import { mapState } from 'vuex';
import BarcodePopup from '@/components/BarcodePopup.vue';
import Spinner from '@/components/Spinner';

export default {
  name: 'CheckIn',
  components: { BarcodePopup, Spinner },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    console.log(to, from);

    next((vm) => {
      let that = vm;
      if (that.user && that.user.userId) {
        //
        console.log('campaignId:', to.params.id);
        // if(Object.keys(that.campaign) == 0) next("/error")
      } else {
        next('/error');
      }
    });
  },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      barcodePopupOpen: false,
      campaignId: this.$route.params.id,
      campaigns: [],
      campaign: {},
      spin: true,
    };
  },
  computed: {
    ...mapState(['user']),
  },
  mounted() {
    this.getCampaigns().then(() => {
      this.campaign = this.campaigns.find(
        (campaign) => campaign.campaignId == this.campaignId
      );

      this.spin = false;

      if (!this.campaign || Object.keys(this.campaign).length == 0) {
        this.$router.push(`/error`);
        return;
      }
    });
  },
  methods: {
    tsToDate(ts) {
      return moment(ts * 1000).format('YYYY-MM-DD');
    },
    tsToDatetime(ts) {
      return moment(ts * 1000).format('YYYY-MM-DD HH:mm:ss');
    },
    getCampaignsAPI() {
      const publisher = this.$route.meta.publisher;
      const param = publisher ? `&publisherType=${publisher}` : '';
      let config = {
        url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/participations/${this.user.userId}?includeNonJoined=true${param}`,
        method: 'GET',
      };
      return this.$http(config);
    },
    getCampaigns() {
      return this.getCampaignsAPI()
        .then((res) => {
          this.campaigns = res.data.campaigns;
          return res;
        })
        .catch((getCampaignsErr) => {
          this.spin = false;
          console.log('getCampaignsErr: ', getCampaignsErr);
          this.$router.push('/error');
        });
    },
  },
};
</script>
